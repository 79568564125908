import React, {FC} from 'react'
import {Status, PaymentStatus} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {WidgetProps} from '@wix/yoshi-flow-editor'
import {Card, Divider, Text, TextButton, TextButtonPriority, Spinner} from 'wix-ui-tpa/cssVars'
import {utcToZonedTime} from '@wix/table-reservations-lib/timezone'
import {useSettings, useStyles} from '@wix/tpa-settings/react'

import {Map} from '../components/Map'
import {getLongDate} from '../../../utils/dateString'
import {CancelModal} from '../components/CancelModal'
import {ConfirmationErrorModal} from '../components/ConfirmationErrorModal'
import {withReservationConfirmationStorageProvider} from '../storage'
import stylesParams from '../stylesParams'
import {RtlProvider} from '../../../components-shared/RtlProvider'
import {settingsParams} from '../settingsParams'
import {TextAlignment} from '../../../types/createStylesParams'
import {LayoutSize} from '../../../components-shared/LayoutSizeProvider/types'
import {withLayoutSizeProvider} from '../../../components-shared/LayoutSizeProvider'

import {useHooks} from './useHooks'
import {classes, st} from './Widget.st.css'
import {
  LAYOUT_BREAKPOINTS_START,
  ALLOWED_STATUSES,
  reservationConfirmationDataHooks,
  STATUS_TO_CTA_BUTTON_DATA_HOOK_MAP,
  STATUS_TO_KEYS_MAP,
} from './constants'

const Widget: FC<WidgetProps> = () => {
  const {
    t,
    layoutSize,
    isCancelDialogOpened,
    reservation,
    orderDetails,
    shouldShowOrderDetails,
    formattedAddress,
    locationName,
    calendarHref,
    messageType,
    timeZone,
    goToNewReservation,
    openCancelDialog,
    closeCancelDialog,
    phoneNumber,
    regionalSettings,
    isLoading,
    shouldShowNotFoundErrorText,
    feePerGuestWithCurrency,
    isPaymentTokenizationExperimentEnabled,
    onAddToCalendarClick,
  } = useHooks()
  const styles = useStyles()
  const settings = useSettings()

  const titleTag =
    styles.get(stylesParams.titleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.titleFont).htmlTag

  const textAlignment = settings.get(settingsParams.textAlignment)
  const textAlignmentClass = TextAlignmentToClass[textAlignment]

  const subtitleTag =
    styles.get(stylesParams.subtitleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.subtitleFont).htmlTag

  const formattedDate = getLongDate(
    utcToZonedTime(reservation?.details?.startDate ?? new Date(), timeZone),
    regionalSettings,
  )

  const isXS = layoutSize === LayoutSize.XS
  const reservationNotPaid = reservation && reservation.paymentStatus === PaymentStatus.NOT_PAID
  const orderRefunded = orderDetails && orderDetails.balanceSummary?.refunded?.amount !== '0'

  const renderCalendarCTA = (status?: Status) => {
    if (status === Status.RESERVED) {
      return (
        <TextButton
          as="a"
          href={calendarHref}
          // @ts-expect-error issue in types
          target="_blank"
          className={st(classes.linkButton, classes.calendarLink)}
          priority={TextButtonPriority.primary}
          onClick={onAddToCalendarClick}
        >
          {t('uou-reservations.reservation-confirmation.calendar-cta')}
        </TextButton>
      )
    }
    return null
  }

  const renderCancelCTA = () => {
    if (!reservation) {
      return null
    }

    return (
      <TextButton
        className={classes.linkButton}
        priority={TextButtonPriority.primary}
        data-hook={STATUS_TO_CTA_BUTTON_DATA_HOOK_MAP[reservation.status!]}
        onClick={() => {
          if (reservation.status === Status.RESERVED || reservation.status === Status.REQUESTED) {
            openCancelDialog()
          } else {
            goToNewReservation(reservation)
          }
        }}
      >
        {t(STATUS_TO_KEYS_MAP[reservation.status!].cta)}
      </TextButton>
    )
  }

  const renderOrderDetails = () => {
    if (!reservation) {
      return null
    }

    return (
      <div className={classes.orderDetails}>
        <Text className={classes.bodyText}>
          {t('uou-reservations.reservation-confirmation.order-summary')}
        </Text>
        <div className={classes.reservationInfoWrap}>
          <Text className={classes.text} tagName="p">
            {t('uou-reservations.shared.price-for-reserved-seats', {
              pricePerGuest: feePerGuestWithCurrency,
              guestNumber: reservation.details?.partySize,
            })}
          </Text>
          <Text className={classes.bodyText}>
            {orderDetails?.priceSummary?.subtotal?.formattedAmount}
          </Text>
        </div>
        <div className={classes.reservationInfoWrap}>
          <Text className={classes.bodyText}>
            {t('uou-reservations.reservation-confirmation.order-summary.tax')}
          </Text>
          <Text className={classes.bodyText}>
            {orderDetails?.priceSummary?.tax?.formattedAmount}
          </Text>
        </div>
        <div className={classes.reservationInfoWrap}>
          <Text className={classes.bodyText}>
            {t('uou-reservations.reservation-confirmation.order-summary.total')}
          </Text>
          <Text className={classes.bodyText}>
            {orderDetails?.priceSummary?.total?.formattedAmount}
          </Text>
        </div>
        {isPaymentTokenizationExperimentEnabled && (
          <>
            <Divider className={classes.orderDivider} />
            <div className={classes.reservationInfoWrap}>
              <Text className={classes.bodyText}>
                {t('uou-reservations.reservation-confirmation.order-summary.paid')}
              </Text>
              <Text className={classes.bodyText}>
                {reservationNotPaid
                  ? orderDetails?.balanceSummary?.paid?.formattedAmount
                  : orderDetails?.priceSummary?.total?.formattedAmount}
              </Text>
            </div>
            {orderRefunded && (
              <div className={classes.reservationInfoWrap}>
                <Text className={classes.bodyText}>
                  {t('uou-reservations.reservation-confirmation.order-summary.refunded')}
                </Text>
                <Text className={classes.bodyText}>
                  {orderDetails?.balanceSummary?.refunded?.formattedAmount}
                </Text>
              </div>
            )}
            {reservationNotPaid && (
              <div className={classes.reservationInfoWrap}>
                <Text className={classes.bodyText}>
                  {t('uou-reservations.reservation-confirmation.order-summary.due-later')}
                </Text>
                <Text className={classes.bodyText}>
                  {orderDetails?.priceSummary?.total?.formattedAmount}
                </Text>
              </div>
            )}
          </>
        )}
      </div>
    )
  }

  if (isLoading) {
    return (
      <RtlProvider>
        <main className={st(classes.root, {[layoutSize]: true})}>
          <Spinner isCentered className={classes.loadingSpinner} />
        </main>
      </RtlProvider>
    )
  }

  if (!reservation?.id) {
    return (
      <RtlProvider>
        <main className={st(classes.root, {[layoutSize]: true})}>
          <Text tagName={titleTag} className={st(classes.title, textAlignmentClass)}>
            {shouldShowNotFoundErrorText
              ? t('uou-reservations.reservation-confirmation.reservation-not-found')
              : t('uou-reservations.reservation-confirmation.refresh-page-error')}
          </Text>
        </main>
      </RtlProvider>
    )
  }

  if (!(ALLOWED_STATUSES as unknown as Status[]).includes(reservation.status!)) {
    return (
      <RtlProvider>
        <main className={st(classes.root, {[layoutSize]: true}, textAlignmentClass)}>
          <Text tagName={titleTag} className={classes.title}>
            {t('uou-reservations.reservation-confirmation.no-available')}
          </Text>
        </main>
      </RtlProvider>
    )
  }

  let titleText

  switch (reservation.status) {
    case Status.RESERVED: {
      titleText = settings.get(settingsParams.titleReservedText)
      break
    }
    case Status.REQUESTED: {
      titleText = settings.get(settingsParams.titleRequestedText)
      break
    }
    default: {
      titleText = t(STATUS_TO_KEYS_MAP[reservation.status!].title)
    }
  }

  return (
    <RtlProvider>
      <main
        className={st(classes.root, {[layoutSize]: true})}
        data-hook={reservationConfirmationDataHooks.root()}
      >
        <div className={classes.contentWrapper}>
          <Text
            tagName={titleTag}
            className={st(classes.title, textAlignmentClass)}
            role={reservation.status === Status.CANCELED ? 'alert' : undefined}
          >
            {titleText}
          </Text>
          <Text tagName={subtitleTag} className={st(classes.subtitle, textAlignmentClass)}>
            {t(STATUS_TO_KEYS_MAP[reservation.status!].subtitle, {messageType})}
          </Text>

          {isXS && <Divider className={classes.mobileDivider} />}

          <Card className={classes.reservationDetails}>
            <Card.Container className={classes.reservationInfo}>
              <div className={classes.reservationInfoWrap}>
                <div className={classes.reservationInfoText}>
                  <Text
                    className={st(classes.reservationInfoTextTitle, classes.bodyText)}
                    tagName="h2"
                  >
                    {t(STATUS_TO_KEYS_MAP[reservation.status!].details, {
                      formattedDate,
                      partySize: t('uou-reservations.shared.guests', {
                        number: reservation.details?.partySize ?? 0,
                      }),
                    })}
                  </Text>

                  {reservation.status !== Status.DECLINED ? (
                    <>
                      <Text className={classes.bodyText}>
                        {formattedDate}
                        {!isXS ? ', ' : ''}
                      </Text>
                      {isXS ? <br /> : null}
                      <Text className={classes.bodyText}>
                        {t('uou-reservations.shared.guests', {
                          number: reservation.details?.partySize ?? 0,
                        })}
                      </Text>
                    </>
                  ) : null}
                </div>

                {!isXS && renderCalendarCTA(reservation.status)}
              </div>

              {reservation.status === Status.DECLINED && reservation.declineReason ? (
                <div className={classes.declineReasonWrapper}>
                  <Text className={classes.bodyText}>"{reservation.declineReason}"</Text>
                </div>
              ) : null}

              <Divider className={classes.divider} />

              {shouldShowOrderDetails && (
                <>
                  {isPaymentTokenizationExperimentEnabled && reservationNotPaid ? (
                    <div className={classes.notPaidOrderDetails}>
                      <div className={classes.notPaidOrderDetailsMessage}>
                        <Text className={classes.bodyText}>
                          {reservation.status === Status.REQUESTED
                            ? t(
                                'uou-reservations.reservation-confirmation.order-summary.charge-once-approve',
                              )
                            : t(
                                'uou-reservations.reservation-confirmation.order-summary.reservation-approved-but-not-charged',
                              )}
                        </Text>
                        {!isXS && renderCancelCTA()}
                      </div>
                      {renderOrderDetails()}
                    </div>
                  ) : (
                    renderOrderDetails()
                  )}
                </>
              )}

              {isXS && renderCalendarCTA(reservation.status)}

              {isPaymentTokenizationExperimentEnabled && !reservationNotPaid && renderCancelCTA()}
              {isPaymentTokenizationExperimentEnabled &&
                reservationNotPaid &&
                isXS &&
                renderCancelCTA()}

              {!isPaymentTokenizationExperimentEnabled && renderCancelCTA()}
            </Card.Container>
          </Card>

          {isXS && <Divider className={classes.mobileDivider} />}

          <Card className={classes.locationDetails}>
            <Card.Container className={classes.locationInfo}>
              <Text className={classes.bodyText} tagName="h2">
                {t('uou-reservations.reservation-confirmation.address-text')}
              </Text>
              <Text className={classes.bodyText} tagName="p">
                {locationName}
              </Text>
              <Text className={classes.bodyText} tagName="p">
                {formattedAddress}
              </Text>
              {phoneNumber ? (
                <Text
                  /* @ts-expect-error */
                  href={`tel:${phoneNumber}`}
                  className={classes.bodyText}
                  tagName="a"
                >
                  {t('uou-reservations.reservation-confirmation.phone-number', {phoneNumber})}
                </Text>
              ) : null}
            </Card.Container>

            <Card.Container className={classes.locationMap}>
              <Map address={formattedAddress} />
            </Card.Container>
          </Card>

          <CancelModal isOpen={isCancelDialogOpened} onClose={closeCancelDialog} />
          <ConfirmationErrorModal />
        </div>
      </main>
    </RtlProvider>
  )
}

const TextAlignmentToClass: Record<TextAlignment, string> = {
  [TextAlignment.Left]: classes.left,
  [TextAlignment.Center]: classes.center,
  [TextAlignment.Right]: classes.right,
}

export default withLayoutSizeProvider(
  withReservationConfirmationStorageProvider(Widget),
  LAYOUT_BREAKPOINTS_START,
)
